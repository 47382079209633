<template>
  <div>
    <el-select
      v-model="selectedValue"
      :disabled="disabled"
      @focus="load_component()"
      size="mini"
      filterable
      clearable
      :placeholder="getTitle(editor)"
      @change="changed"
      :name="editor"
    >
      <el-option
        v-for="item in listdata"
        :key="item.id"
        :label="item.name"
        :value="item.id"
      ></el-option>
      <!--<el-divider style="margin: 0 !important"></el-divider>
      <el-option v-if="!disabled" key="" label="" value="">
        <template>
          <el-button
            @click.native.prevent="show_create_screen"
            type="text"
            size="mini"
          >
            Create New {{ getTitle(editor) }}
          </el-button>
        </template>
      </el-option>-->
      <template v-slot:empty>
        <el-button
          @click.native.prevent="show_create_screen"
          type="text"
          size="mini"
          style="padding: 10px; width: 100%; text-align: left"
        >
          Create New {{ getTitle(editor) }}
        </el-button>
      </template>
    </el-select>
    <comp
      v-if="show"
      :show="showForm"
      :apiUrl="editor"
      api="create"
      v-on:close="close"
      :perm="permissions"
      v-on:created="created"
      :popup="true"
    ></comp>
  </div>
</template>
<script>
import Vue from "vue";
import { Divider } from "element-ui";
Vue.use(Divider);
export default {
  name: "SelectListControl",
  components: {},
  props: {
    value: null,
    disabled: {
      type: Boolean,
      default: false,
    },
    list: {
      type: Array,
      default: null,
    },
    editor: {
      type: String,
      default: "Select",
    },
    extraid: null,
    view: null,
  },
  watch: {
    selectedValue: function () {
      this.$emit("input", this.selectedValue);
      this.$emit("change");
    },
    value: function () {
      console.log("value " + this.value);
      this.selectedValue = this.value;
    },
    extraid: function () {
      if (!this.disabled) this.selectedValue = null;
      this.reload_list();
    },
  },
  data: function () {
    return {
      selectedValue: null,
      show: false,
      showForm: false,
      listdata: [],
      permissions: [],
    };
  },
  mounted() {
    this.reload_list();
  },
  methods: {
    load_component() {
      if (this.disabled) return;
      this.$options.components["comp"] = () =>
        import("@/components/" + this.getComponent());
      this.show = true;
    },
    getTitle(value) {
      if (this.empty(value)) return;
      let r = value.split("/");
      return this.toTitleCase(r[2]);
    },
    show_create_screen() {
      console.log(this.showForm);
      this.showForm = true;
      console.log(this.showForm);
    },
    getComponent() {
      let r = this.editor.split("/");
      return (
        r[0].replace("-", "") + "/" + r[1].replace("-", "") + "/" + "form.vue"
      );
    },
    created() {
      //this.reload_list();
    },
    reload_list() {
      let f = {
        extraid: this.extraid,
      };
      this.api(this.editor + "/list", f)
        .then((response) => {
          this.listdata = response.data;
          this.permissions = response.permissions;
          this.showForm = false;
        })
        .catch(function (error) {
          if (error.response) {
            this.showError(error.response, "Select List Control");
          } else {
            this.error(
              "Unknown errror, Contact System Administrator",
              "Select List Control"
            );
          }
        });
    },
    changed() {
      this.$emit("change");
    },
    close() {
      this.reload_list();
      this.showForm = !this.showForm;
    },
  },
};
</script>
<style scoped>
.is-empty {
  height: 0px !important;
}
</style>
